import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { AppConfig } from "../app-config";


const token = typeof localStorage !== "undefined" ? localStorage.getItem("user-token") : undefined;


const headers = token ? { Authorization: `Bearer ${token}` } : {};


const safeHeaders = Object.entries(headers).reduce((acc, [key, value]) => {
    if (typeof value === "string") {
        acc[key] = value;
    }
    return acc;
}, {});


const link = new BatchHttpLink({
    uri: AppConfig.api_url + "/graphql",
    headers: safeHeaders,
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            ProductEntity: {
                fields: {
                    attributes: {
                        merge(existing = {}, incoming) {
                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                },
            },
        },
    }),
    link,
});

export default client;
