import {Fragment, useEffect} from 'react';
import Head from 'next/head';
import CartContextProvider from '@global/CartContext';
import CompareContextProvider from '@global/CompareContext';
import ProductsContextProvider from '@global/ProductsContext';
import WishlistContextProvider from '@global/WishlistContext';
import 'react-tippy/dist/tippy.css';
import '@assets/scss/style.scss';
import client from 'src/apollo/apollo-client';
import {ApolloProvider} from '@apollo/client';
import ProductGroupsContextProvider from '@global/ProductGroups';
import * as Sentry from '@sentry/nextjs';
import {useRouter} from 'next/router';
import {pageview} from '@utils/analytics';
import ParametersContextProvider from '@global/ParametersContext';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import ChatBotWidget from "@components/ChatBot/ChatBotWidget";

const Wokiee = ({Component, pageProps}) => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url) => {
            pageview(url);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.init({
            environment: 'production',
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            tracesSampleRate: 1.0,
        });
    }

    return (
        <Fragment>
            <Head>
                <title>San Marino</title>
            </Head>
            <ApolloProvider client={client}>
                <ParametersContextProvider>
                    <CartContextProvider>
                        <CompareContextProvider>
                            <WishlistContextProvider>
                                <ProductsContextProvider>
                                    <ProductGroupsContextProvider>
                                        <Component {...pageProps} />
                                        <ChatBotWidget/>
                                        <ToastContainer/>
                                    </ProductGroupsContextProvider>
                                </ProductsContextProvider>
                            </WishlistContextProvider>
                        </CompareContextProvider>
                    </CartContextProvider>
                </ParametersContextProvider>
            </ApolloProvider>
        </Fragment>
    );
};

export default Wokiee;
