import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "@apollo/client";
import { User } from "src/graphql/query/user";

const WidgetBotAssistant = () => {
    const [isChatbotVisible, setIsChatbotVisible] = useState(false);

    const { data, loading, error } = useQuery(User);

    const chatbotUrl = process.env.NEXT_PUBLIC_CHATBOT_URL;
    const logo = "/favicon.ico";
    const user = data?.me;

    const toggleChatbotVisibility = () => {
        if (user?.email?.endsWith("@sanmarino.com.uy")) {
            setIsChatbotVisible(!isChatbotVisible);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="general-style-widget">
            {!loading && !error && !isChatbotVisible ? (
                <button onClick={toggleChatbotVisibility} className="button-style-widget">
                    <img src={logo} className="image-style-widget" alt="Logo" />
                </button>
            ) : null}

            <AnimatePresence>
                {isChatbotVisible && (
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 20}}
                        transition={{duration: 0.2}}
                        className="widget-chatbox"
                    >
                        <div className="frame-background-style-widget">
                            <button id="close-chatbot" onClick={toggleChatbotVisibility} className="close-chatbot">
                                <span className="material-symbols-outlined">keyboard_arrow_down</span>
                            </button>
                            <iframe src={chatbotUrl} className="in-frame-background-style-widget"
                                    title="Chatbot"></iframe>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default WidgetBotAssistant;
